import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Box, Typography, Grid, SelectChangeEvent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { get, isEmpty, map, size } from 'lodash';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'elements/Loader';
import DropdownField from 'elements/DropdownField';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';

import { getUserName, getQueryParam } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';
import { useGetRecentActivityQuery } from 'api/bcmDashboardApi';
import {
  getRecentActivity,
  updateFilters,
} from 'pages/BcmDashboard/components/RecentActivity/recentActivitySlice';

import EmptyStateMessage from 'pages/BcmDashboard/components/MemberOverview/EmptyStateMessage';
import { Container, StickyDataGrid } from 'pages/BcmDashboard/components/style';
import { scrollCellIntoView } from 'utils/utility';
import {
  SortedDescendingIcon,
  SortedAscendingIcon,
  UnsortedIcon,
} from 'pages/BcmDashboard/components/MemberOverview/index';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    width: 250,
    renderHeader: () => <Typography variant='h4'>Date</Typography>,
    valueFormatter: params => {
      if (!params.value) {
        return '--';
      }
      return dayjs(params.value)?.format('MMMM DD, YYYY');
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderHeader: () => <Typography variant='h4'>Name</Typography>,
    valueFormatter: params => params.value || '--',
  },
  {
    field: 'pathName',
    headerName: 'Path',
    width: 250,
    renderHeader: () => <Typography variant='h4'>Path</Typography>,
    valueFormatter: params => params.value || '--',
  },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 360,
    renderHeader: () => <Typography variant='h4'>Activity</Typography>,
    valueFormatter: params => params.value || '--',
  },
];

const RecentActivity: FunctionComponent<any> = () => {
  const [selectedClub, setSelectedClub] = useState('');
  const [sortModel, setSortModel] = useState<any>([{}]);
  const dispatch = useDispatch();

  const { roles: userRoles = [] } = useAppContext();

  // const { data, isFetching, isLoading, isSuccess, isError } =
  //   useGetRecentActivityQuery(
  //     {
  //       clubId: selectedClub,
  //     },
  //     { skip: !selectedClub },
  //   );

  const {
    data = [],
    isLoading,
    isError,
    isFilterUpdated,
  } = useSelector((state: any) => state.userRecentActivity);

  const clubOptions = useMemo(
    () =>
      userRoles.reduce((acc: any, value: any) => {
        const { name, uuid, roles = [] } = value;

        const hasBcmRole = roles.some(({ isBcm }: any) => isBcm);

        if (!hasBcmRole) {
          return acc;
        }

        return [...acc, { label: name, value: uuid }];
      }, []),
    [userRoles],
  );

  useEffect(() => {
    if (!selectedClub) {
      return;
    }

    dispatch(updateFilters());
    dispatch(
      getRecentActivity({
        club: selectedClub,
        resetInitialState: true,
      }),
    );
  }, [dispatch, selectedClub]);

  const rows = useMemo(() => {
    return map(get(data, 'results', []), (value: any, index) => {
      const { created, description, id, pathName, user } = value;

      return {
        id,
        name: getUserName(user),
        pathName,
        activity: description,
        date: created,
      };
    });
  }, [data]);

  const onSortModelChange = useCallback(
    (newSortModel: any) => {
      const { field, sort } = sortModel[0] || {};
      if (isEmpty(newSortModel) && sort === 'desc') {
        setSortModel([{ field, sort: 'asc' }]);

        return;
      }

      setSortModel(newSortModel);
    },
    [sortModel],
  );

  useEffect(() => {
    if (clubOptions.length === 1) {
      setSelectedClub(clubOptions[0].value);
    }
  }, [clubOptions]);

  const handleClubChange = (event: SelectChangeEvent) => {
    setSelectedClub(event.target.value);
  };

  const getNextData = useCallback(() => {
    const next = get(data, 'next', null);
    const { page }: any = getQueryParam(next || '');

    if (!next) {
      return;
    }

    dispatch(
      getRecentActivity({ club: selectedClub, page }),
    );
  }, [data, dispatch, selectedClub]);

  console.log('----->>>', get(data, 'results', []).length, get(data, 'count'))

  return (
    <Box mb={10}>
      <Typography variant='h2'>Recent Activity - Last 60 Days</Typography>
      <Grid
        container
        mt={2}
        gap={3}
        alignItems='flex-end'
        justifyContent='space-between'
      >
        {size(clubOptions) > 1 && (
          <Grid item xs={12} sm={5}>
            <FormControlLabel
              label='Club'
              labelPlacement='top'
              control={
                <DropdownField
                  value={selectedClub}
                  options={clubOptions}
                  handleChange={handleClubChange}
                  placeholder='Select a Club'
                />
              }
            />
          </Grid>
        )}
      </Grid>
      <Container>
        {isLoading && isFilterUpdated ? (
          <Loader />
        ) : !isEmpty(data?.results || data) && selectedClub && !isError ? (
          <>
            <Grid
              container
              gap={3}
              alignItems='flex-end'
              justifyContent='space-between'
            >
              <Grid item xs={12} sm={5} className='text-end'>
                {/* <Button onClick={() => {}} color='ternary'>
                  <FileDownloadOutlinedIcon className='mr-5' />
                  Export Excel/CSV
                </Button> */}
              </Grid>
            </Grid>{' '}
          <InfiniteScroll
            dataLength={get(data, 'results', []).length}
            next={getNextData}
            hasMore={get(data, 'next', '') ? true : false}
            loader={
              <div className='loader' key={0}>
                <Loader />
              </div>
            }
            scrollableTarget='scrollableDiv'
            style={{ overflow: 'unset' }}
          >
            <StickyDataGrid
              autoHeight
              getRowHeight={() => 'auto'}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              slots={{
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
                columnUnsortedIcon: UnsortedIcon,
              }}
              rows={rows}
              className={classNames('activity', {
                'disable-pagination': isLoading,
              })}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              hideFooter
              slotProps={{
                cell: {
                  onFocus: (event: any) =>
                    scrollCellIntoView(event.currentTarget),
                },
              }}
            />
          </InfiniteScroll>
          </>
        ) : (
          <EmptyStateMessage
            selectedClub={selectedClub}
            isSuccess={!isEmpty(data)}
            data={data?.results || data}
            isError={isError}
          />
        )}
      </Container>
    </Box>
  );
};

export default RecentActivity;
