import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Breadcrumbs,
  Box,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import {
  Link as RouterLink,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInView } from 'react-intersection-observer';

import { DashboardRoutes as Routes } from 'routes';
import Loader from 'elements/Loader';
import {
  fetchClubMembers,
  selectAllClubMembers,
  resetClubMembers,
  selectClubMembers,
} from '../ducks/slice';
import ClubCard from './ClubCard';
import { getPlatformName } from 'utils/utility';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

const ClubDetails = () => {
  const { state: routerState } = useLocation();
  const { clubId } = useParams<any>();
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const user: any = getAuthenticatedUser();

  const { isFetching } = useSelector(selectClubMembers);
  const clubMembers = useSelector(selectAllClubMembers);

  useEffect(() => {
    dispatch(fetchClubMembers(clubId));

    return () => {
      dispatch(resetClubMembers());
    };
  }, [dispatch, clubId]);

  useEffect(() => {
    if (inView) {
      dispatch(fetchClubMembers(clubId));
    }
  }, [dispatch, clubId, inView]);

  return (
    <Container>
      <Box role='presentation' mt={7.5}>
        <Breadcrumbs role="navigation" aria-label="breadcrumbs">
          <Link
            component={RouterLink}
            to={`${getPlatformName()}`}
            underline='none'
            variant='h5'
            color='secondary'
          >
            Home
          </Link>
          <Link
            component={RouterLink}
            to={Routes.CLUBS.path}
            underline='none'
            variant='h5'
            color='secondary'
          >
            My Clubs
          </Link>
          {routerState?.clubName ? (
            <Typography variant='h5' color='ternary.dark' component='p'>
              {routerState.clubName}
            </Typography>
          ) : null}
        </Breadcrumbs>
      </Box>

      <Box mt={7.5}>
        <Typography variant='h1' pb={5}>My Clubs</Typography>
        {routerState?.clubName ? (
          <Typography variant='h3' component='h2' color='ternary.dark' mb={0}>
            {routerState.clubName}
          </Typography>
        ) : null}

        <Box mt={4}>
          {clubMembers.length ? (
            <Grid container spacing={3}>
              {clubMembers.filter(({ user:member }: any) => member.id !== user.userId).map(({ user }, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={user.id}
                  ref={i + 1 === clubMembers.length ? ref : null}
                >
                  <ClubCard
                    member={user}
                    clubId={clubId}
                    clubName={routerState.clubName}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {isFetching ? (
            <Box py={4}>
              <Loader />
            </Box>
          ) : null}
        </Box>
      </Box>

      {!isFetching && clubMembers.length <= 0 ? (
        <Typography variant='h1' textAlign='center' mt={20}>
          Club not found
        </Typography>
      ) : null}
    </Container>
  );
};

export default ClubDetails;
